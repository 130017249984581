// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import SvgIconStyle from '../../../components/SvgIconStyle';
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
};

const navConfig = [
   // ACTIVE SECTIONS
  // ----------------------------------------------------------------------
  {
    subheader: 'منو مدیریت',
    items: [
      // ACTIVE SECTIONS : CUSTOMER
      // {
      //   title: 'customer',
      //   path: PATH_DASHBOARD.customer.root,
      //   icon: ICONS.user,
      //   children: [
      //     // { title: 'profile', path: PATH_DASHBOARD.customer.profile },
      //     // { title: 'cards', path: PATH_DASHBOARD.user.cards },
      //     { title: 'list', path: PATH_DASHBOARD.customer.list },
      //     { title: 'create', path: PATH_DASHBOARD.customer.newCustomer },
      //     // { title: 'edit', path: PATH_DASHBOARD.customer.editById },
      //     { title: 'account', path: PATH_DASHBOARD.customer.account },
      //   ],
      // },

      // MANAGEMENT : DEPO
      // {
      //   title: 'depo',
      //   path: PATH_DASHBOARD.depo.root,
      //   icon: <Iconify icon={'ic:baseline-maps-home-work'}/>,
      //   children: [
      //     // { title: 'shop', path: PATH_DASHBOARD.depo.shop },
      //     // { title: 'product', path: PATH_DASHBOARD.eCommerce.productById },
      //     { title: 'list', path: PATH_DASHBOARD.depo.list },
      //     { title: 'create', path: PATH_DASHBOARD.depo.newProduct },
      //     // { title: 'edit', path: PATH_DASHBOARD.depo.editProduct },
      //     // { title: 'checkout', path: PATH_DASHBOARD.invoice.checkout },
      //     // { title: 'invoice', path: PATH_DASHBOARD.invoice.invoice },
      //   ],
      // },

      // MANAGEMENT : Field
      {
        title: 'رشته',
        path: PATH_DASHBOARD.field.root,
        icon: <Iconify icon={'ic:baseline-maps-home-work'}/>,
        children: [
          { title: 'لیست', path: PATH_DASHBOARD.field.list },
          { title: 'جدید', path: PATH_DASHBOARD.field.newField },
        ],
      },

        // MANAGEMENT : Lesson
        {
          title: 'درس',
          path: PATH_DASHBOARD.field.root,
          icon: <Iconify icon={'ic:baseline-maps-home-work'}/>,
          children: [
            { title: 'لیست', path: PATH_DASHBOARD.lesson.list },
            { title: 'جدید', path: PATH_DASHBOARD.lesson.newLesson },
          ],
        },

      // MANAGEMENT : INVOICE
      // {
      //   title: 'invoice',
      //   path: PATH_DASHBOARD.invoice.root,
      //   icon: <Iconify icon={'la:file-invoice-dollar'}/>,
      //   children: [
      //     // { title: 'shop', path: PATH_DASHBOARD.depo.shop },
      //     // { title: 'product', path: PATH_DASHBOARD.depo.product },
      //     // { title: 'list', path: PATH_DASHBOARD.depo.list },
      //     // { title: 'create', path: PATH_DASHBOARD.depo.newProduct },
      //     // { title: 'edit', path: PATH_DASHBOARD.depo.editProduct },
      //     { title: 'create', path: PATH_DASHBOARD.invoice.newInvoice },
      //     { title: 'invoice', path: PATH_DASHBOARD.invoice.invoice },
      //   ],
      // },

            // MANAGEMENT : ACCOUNTING
            // {
            //   title: 'accounting',
            //   path: PATH_DASHBOARD.accounting.root,
            //   icon: <Iconify icon={'la:folder-plus'}/>,
            //   children: [
            //     // { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
            //     { title: 'single', path: PATH_DASHBOARD.accounting.single },
            //     { title: 'list', path: PATH_DASHBOARD.accounting.list },
            //     { title: 'create', path: PATH_DASHBOARD.accounting.newTransaction },
            //     { title: 'edit', path: PATH_DASHBOARD.accounting.edit },
            //     // { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
            //     // { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice },
            //   ],
            // },
             // MANAGEMENT : TOOLS
            //  {
            //   title: 'tools',
            //   path: PATH_DASHBOARD.tools.root,
            //   icon: <Iconify icon={'la:tools'}/>,
            //   children: [
            //     { title: 'filemanager', path: PATH_DASHBOARD.tools.fileManager },
            //     { title: 'workflow', path: PATH_DASHBOARD.tools.single },
            //     { title: 'category', path: PATH_DASHBOARD.tools.category },
            //     // { title: 'list', path: PATH_DASHBOARD.accounting.list },
            //     // { title: 'create', path: PATH_DASHBOARD.accounting.newTransaction },
            //     // { title: 'edit', path: PATH_DASHBOARD.accounting.edit },
            //     // { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
            //     // { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice },
            //   ],
            // },
             // MANAGEMENT : TICKET
            //  {
            //   title: 'ticket',
            //   path: PATH_DASHBOARD.ticket.root,
            //   icon: <Iconify icon={'la:ticket-alt'}/>,
            //   children: [
            //     // { title: 'filemanager', path: PATH_DASHBOARD.tools.fileManager },
            //     // { title: 'workflow', path: PATH_DASHBOARD.tools.single },
            //     { title: 'list', path: PATH_DASHBOARD.ticket.list },
            //     // { title: 'create', path: PATH_DASHBOARD.accounting.newTransaction },
            //     // { title: 'edit', path: PATH_DASHBOARD.accounting.edit },
            //     // { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
            //     // { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice },
            //   ],
            // },
            

      // MANAGEMENT : BLOG
      // {
      //   title: 'blog',
      //   path: PATH_DASHBOARD.blog.root,
      //   icon: ICONS.blog,
      //   children: [
      //     { title: 'posts', path: PATH_DASHBOARD.blog.posts },
      //     { title: 'post', path: PATH_DASHBOARD.blog.postById },
      //     { title: 'new post', path: PATH_DASHBOARD.blog.newPost },
      //   ],
      // },
    ],
  },


  // GENERAL
  // ----------------------------------------------------------------------

  {
    subheader: 'عمومی',
    items: [

      // MANAGEMENT : Field
      {
        title: 'مدارک',
        path: PATH_DASHBOARD.doc.root,
        icon: <Iconify icon={'ic:baseline-maps-home-work'}/>,
        children: [
          // { title: 'لیست', path: PATH_DASHBOARD.field.list },
          { title: 'ریزنمرات', path: PATH_DASHBOARD.doc.newPoints },
        ],
      },
    ],
  },
  // {
  //   subheader: 'general',
  //   items: [
  //     { title: 'app', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
  //     { title: 'e-commerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
  //     { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
  //     { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
  //     { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
  //   ],
  // },
  

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    // subheader: 'management',
    items: [
      // MANAGEMENT : USER
      // {
      //   title: 'user',
      //   path: PATH_DASHBOARD.user.root,
      //   icon: ICONS.user,
      //   children: [
      //     { title: 'profile', path: PATH_DASHBOARD.user.profile },
      //     { title: 'cards', path: PATH_DASHBOARD.user.cards },
      //     { title: 'list', path: PATH_DASHBOARD.user.list },
      //     { title: 'create', path: PATH_DASHBOARD.user.newUser },
      //     { title: 'edit', path: PATH_DASHBOARD.user.editById },
      //     { title: 'account', path: PATH_DASHBOARD.user.account },
      //   ],
      // },

      // MANAGEMENT : E-COMMERCE
      // {
      //   title: 'e-commerce',
      //   path: PATH_DASHBOARD.eCommerce.root,
      //   icon: ICONS.cart,
      //   children: [
      //     { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
      //     { title: 'product', path: PATH_DASHBOARD.eCommerce.productById },
      //     { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
      //     { title: 'create', path: PATH_DASHBOARD.eCommerce.newProduct },
      //     { title: 'edit', path: PATH_DASHBOARD.eCommerce.editById },
      //     { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
      //     { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice },
      //   ],
      // },

      // MANAGEMENT : BLOG
      // {
      //   title: 'blog',
      //   path: PATH_DASHBOARD.blog.root,
      //   icon: ICONS.blog,
      //   children: [
      //     { title: 'posts', path: PATH_DASHBOARD.blog.posts },
      //     { title: 'post', path: PATH_DASHBOARD.blog.postById },
      //     { title: 'new post', path: PATH_DASHBOARD.blog.newPost },
      //   ],
      // },
    ],
  },

  // APP
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     {
  //       title: 'mail',
  //       path: PATH_DASHBOARD.mail.root,
  //       icon: ICONS.mail,
  //       info: (
  //         <Label variant="outlined" color="error">
  //           +32
  //         </Label>
  //       ),
  //     },
  //     { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
  //     { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
  //     {
  //       title: 'kanban',
  //       path: PATH_DASHBOARD.kanban,
  //       icon: ICONS.kanban,
  //     },
  //   ],
  // },
];

export default navConfig;
